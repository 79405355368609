(function ($) {
  Drupal.behaviors.offerPromoCarouselV1 = {
    attach: function (context) {
      $('.offer-promo-carousel-v1').each(function () {
        var $tmpl = $(this);
        var $list = $tmpl.children('ul');
        var numSlides = $list.children('li').length;
        var loopOpt = parseInt($list.attr('data-cycle-loop'));
        var stopOn = parseInt($list.attr('stop-on'));

        // If the editor doesn't have the "enable slideshow" option selected, then
        // hide all LI's except the 1st one
        if ($list.hasClass('cycle-slideshow') == false) {
          $list.children('li').hide();
          $list.children('li').eq(0).show();

          return;
        }

        // Make sure the stopOn variable isn't > than the total # of slides
        if (stopOn > numSlides) {
          stopOn = numSlides;
        }

        // Only create the "pause" event if the "Rotation Options" is set to "Stop on Slide"
        if (loopOpt > 0) {
          var i = 1, cycleNum = 1;

          $list.on('cycle-after', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
            i++;

            if (i > numSlides) {
              i = 1;
              cycleNum++;
            }

            if (cycleNum == 2 && i == stopOn) {
              $list.cycle('pause');
            }
          });
        }
      });
    }
  };
})(jQuery);
